@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$body-font-size: 0.8rem;
$btn-yellow: #ebc571;

.App {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

#App-logo {
  height: 30px;
  width: 190px;
  background-size: contain;
  background-repeat: no-repeat;
}

.App-header {
  width: 100%;
}

body {
  height: 100%;
  background: url('../public/page_background.jpg');
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
  font-size: $body-font-size;
}

.modal {
  font-size: 1rem;
}

.Form-section .form-select,
.Form-section .form-control,
.Form-section .btn {
  font-size: $body-font-size;
}

.Message {
  background: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: 25px;
  padding: 60px;
  font-weight: bold;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.5em;
}

.Quote-section {
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: 25px;
  display: grid;
}

.Section-title {
  font-family: 'Verdana', sans-serif;
  font-weight: bold;
}

.Form-section {
  display: grid;
}

.Form-section .form-label {
  font-weight: bold;
}

.Form-section .form-select,
.Form-section .form-control,
.Form-section .btn {
  border-radius: 15px;
}

.Submit-quote-button {
  background: $btn-yellow;
  border-color: $btn-yellow;
  color: black;
}

.btn-light,
.Decline-quote-button {
  border-color: #ccc;
}

.Submit-quote-button,
.Decline-quote-button {
  font-weight: bold;
  width: 10em;
}

.GST-buttons .btn {
  padding-left: 25px;
  padding-right: 25px;
}

img.Map-image {
  width: 100%;
  border-radius: 10px;
}

.popover img {
  width: 100%;
}

#popover-star-rating {
  max-width: 80vw;
}

.rounded-circle {
  background-color: $btn-yellow;
  color: black;
  font-weight: bold;
  font-size: 0.7rem !important;
  padding: 0.1em 0.5em;
  border: transparent;
  margin-left: 0.4rem;
}

.AutoCompleteInput {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pac-container {
  background-color: white;
  z-index: 2001;
  position: fixed;
  display: inline-block;
}
.modal {
  z-index: 2000;
}
.modal-backdrop {
  z-index: 1000;
}
